import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Input, Select, Col, Row, message, InputNumber, Checkbox, Button, Divider } from "antd";
import { Upload } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import {
	createImageFile,
	setCertificate,
	updateCertificate
} from "../../../modules/actions/managerPanelActions";
import { getSingleItem, getSingleReport, getSingleItemConsultation, getSingleItemSuccess, getSingleReportSuccess } from "../../../modules/actions/dataEntryActions";
import { dateFormat } from "../../../common/common";
import upload from "./../../../Images/upload.png";
import { getColorFormDataAdd, getCutFormDataAdd, getCuttingStyleFormDataAdd, getGridlesFormDataAdd, getObjectsFormDataAdd, getOrigionGemFormDataAdd, getRemarksFormDatAdda, getShapesFormDatAdda, getSpeciesFormDataAdd, getVarietysFormDatAdda } from "../../../modules/actions/FormValues";

const { TextArea } = Input;
const { Option } = Select;

function getBase64(file) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = (error) => reject(error);
	});
}

function SmallGemstoneForm(props) {
	const [loading, setLoading] = useState(false);
	const [imageUrl, setImageUrl] = useState(false);
	const [image, setImage] = useState(false);

	const handleChange = (e) => {
		setImageUrl(e.target.files[0]);
		getBase64(e.target.files[0]).then((data) => {
			setImage(data);
		});
	};

	const [obj, setobj] = useState({
		branchCode: props.permission.branchCode,
		relatedEntryNo: "",
		itemId: "",
		Id_of_temp_data: "",
		serial_number: "",
		certificate_number: "",
		color_gem: "",
		weight: "",
		length_gem: "",
		width_gem: "",
		depth_gem: "",
		description: "",
		comments_gem: "",
		species_gem: "",
		variety_gem: "",
		picture_id: "",
		cart_id: "",
		path: "",
		ext: "",
		Fpath: "",
		columnname: "",
		path2: "",
		ext3: "",
		bpath: "",
		customername: "",
		treatment: "",
		Customer_Number: "",
		eptyp_temp: "",
		Number_Of_Diamonds: "",
		Total_Dia_Weight: "",
		Shap_And_Cut: "",
		DiamondCut: "",
		Diamond_Color: "",
		number: "",
		filename: "",
		date: dateFormat(new Date()),
		shape: "",
		cut: "",
		measurement_mm: "",
		measurements: "",
		measurements2: "",
		measurements3: "",
		measurement_unit: "",
		depth: "",
		table: "",
		gridle: "",
		gridle2: "",
		gridle3: "",
		crown_angle: "",
		pavilion_depth: "",
		culet: "",
		polish: "",
		symmetry: "",
		flourescence: "",
		flourescenceColor: "",
		carat_weight: "",
		weight_unit: "",
		color: "",
		clarity: "",
		cut_grade: "",
		entry_date: dateFormat(new Date()),
		comment: "",
		key_to_symbol: "",
		object: "",
		transparency: "",
		species: "",
		variety: "",
		report_id: 4,
		report_page: "",
		remarks: "",
		finish: "",
		diamond1: "",
		diamond2: "",
		diamond3: "",
		diamond4: "",
		diamond5: "",
		key_symbol1: "",
		key_symbol2: "",
		key_symbol3: "",
		key_symbol4: "",
		key_symbol5: "",
		key_symbol6: "",
		key_symbol7: "",
		key_symbol8: "",
		key_symbol9: "",
		key_symbol10: "",
		key_symbol11: "",
		key_symbol12: "",
		key_symbol13: "",
		key_symbol14: "",
		key_symbol15: "",
		key_symbol16: "",
		key_symbol17: "",
		key_symbol18: "",
		disabled_key_symbol: "",
		origion_gem: "",
		pavilion_angle_d: "",
		color_grade: "",
		clarity_grade: "",
		Enhancements: "",
		RI: "",
		RI1: "",
		Polariscope: "",
		uv_flourescence: "",
		micro_evidence: "",
		cutting_style: "",
		microscope: "",
		FTIR: "",
		UV: "",
		Refractometer: "",
		Lamp: "",
		XRF: "",
		uv_flourescence1: ""
	});
	const [weightUnit, setWeightUnit] = useState('CT')
	const [runOn, setRunOn] = useState(false);

	const [isReported, setIsReported] = useState(false);
	const [isEdit, setIsEdit] = useState(false);

	useEffect(() => {
		var url = new URL(window.location);
		let entryNo = url.searchParams.get("entryNo");
		let id = url.searchParams.get("id");
		let editId = url.searchParams.get("editId");
		let consultation = url.searchParams.get("consultation");
		if (editId) {
			setIsEdit(true);
			props.getSingleReport(editId, props.permission.branchCode);
		} else {
			let objj = { ...obj };
			objj.relatedEntryNo = entryNo;
			objj.itemId = Number(id);
			objj.entry_date = dateFormat(new Date());
			objj.date = dateFormat(new Date());
			if (consultation === '1') {
				props.getSingleItemConsultation(id, props.permission.branchCode);
			} else {
				props.getSingleItem(id, props.permission.branchCode);
			}
			setobj(objj);
		}
	}, []);

	useEffect(() => {
		if (props.singleItemEntry) {
			var url = new URL(window.location);
			let objj = obj;
			objj.carat_weight = Number(props.singleItemEntry.weight);
			objj.color = props.singleItemEntry.color;
			if (props.singleItemEntry.report && props.singleItemEntry.report.length) {
				setIsReported(true);
			} else {
				setIsReported(false);
			}
			setRunOn(runOn ? false : true);
			setobj(objj);
		}
		if (props.singleReportEntry) {
			console.log(props.singleReportEntry);
			if (props.singleReportEntry.relatedEntryNo) {
				console.log("no old entry no");
				setobj(props.singleReportEntry);
			} else {
				props.singleReportEntry.relatedEntryNo = "oldData";
				setobj(props.singleReportEntry);
			}
		}
	}, [props.singleItemEntry, props.singleReportEntry]);

	useEffect(() => { }, [runOn]);

	function backFun() {
		var url = new URL(window.location);
		let path = url.searchParams.get("path");
		if (path) {
			window.location.href = path
		} else {
			props.history.goBack();
		}
	}

	const save = () => {
		var url = new URL(window.location);
		let entryNo = url.searchParams.get("entryNo");
		let data = new FormData();
		for (const property in obj) {
			if (obj[property]) {
				data.append(property, obj[property]);
			} else if (property === "relatedEntryNo" && !obj[property] && entryNo) {
				data.append(property, entryNo);
			}
		}
		let entryId = url.searchParams.get("id");
		data.append("itemId", Number(entryId));
		data.append("product_pic", imageUrl);
		props.setCertificate(data, props.permission.branchCode, clearState, backFun);
	};

	const update = () => {
		let data = new FormData();
		let imageFile = [];
		for (const property in obj) {
			if (property === "product_pic") {
				if (obj[property]) {

					createImageFile(imageUrl ? imageUrl : obj[property]).then((result) => {
						// imageFile.push(result);

						data.append(property, result);
					});
				}
				else {

					createImageFile(imageUrl ? imageUrl : obj[property]).then((result) => {
						// imageFile.push(result);

						data.append(property, result);
					});
				}
			}
			else if (property.toLowerCase().match("fpath")) {
				data.append(property, '')
				// if(obj[property]){

				// createImageFile(imageUrl ? imageUrl : obj[property]).then((result) => {
				// 	// imageFile.push(result);

				// 	data.append(property, result);
				// });
				// }
				// else{

				// createImageFile(imageUrl ? imageUrl : obj[property]).then((result) => {
				// 	// imageFile.push(result);

				// 	data.append(property, result);
				// });
				// }				// data.set(property, "");
			} else if (obj[property]) {
				data.append(property, obj[property]);
			}
		}

		props.updateCertificate(data, props.permission.branchCode, clearState, obj.id, backFun);
	};

	const clearState = () => {
		let objj = obj;
		for (let keys in objj) {
			objj[keys] = "";
		}
		setobj(objj);
		setRunOn(runOn ? false : true);
	};

	const [shapeArr, setShapeArr] = useState([])
	const [shapeName, setShapeName] = useState(false);
	const onShapeChange = (event) => {
		setShapeName(event.target.value);
	};

	const addShapeOpt = () => {
		if (shapeName) {
			if (shapeArr.filter(obj => obj.value.toLowerCase() === shapeName.toLowerCase()).length) {
				message.error('Shape is already exist')
			} else {
				console.log("addItem");
				setShapeArr([...shapeArr, { value: shapeName }]);
				props.getShapesFormDatAdda({ value: shapeName })
				setShapeName(false);
			}
		}
	};


	const [cutArr, setCutArr] = useState([])
	const [cutName, setCutName] = useState(false);
	const onCutChange = (event) => {
		setCutName(event.target.value);
	};

	const addCutOpt = () => {
		if (cutName) {
			if (cutArr.filter(obj => obj.value.toLowerCase() === cutName.toLowerCase()).length) {
				message.error('Cut is already exist')
			} else {
				console.log("addItem");
				setCutArr([...cutArr, { value: cutName }]);
				props.getCutFormDataAdd({ value: cutName })
				setCutName(false);
			}
		}
	};


	const [commentArr, setCommentArr] = useState([])
	const [commentName, setCommentName] = useState(false);
	const onCommentChange = (event) => {
		setCommentName(event.target.value);
		let obj2 = { ...obj };
		obj2.comment = event.target.value;
		setobj(obj2);
	};


	const addCommentOpt = () => {
		if (commentName) {
			console.log("addItem");
			setCommentArr([...commentArr, commentName]);
			setCommentName(false);
		}
	};


	const [colorArr, setColorArr] = useState([])
	const [colorName, setColorName] = useState(false);
	const onColorChange = (event) => {
		setColorName(event.target.value);
	};

	const addColorOpt = () => {
		if (colorName) {
			if (colorArr.filter(obj => obj.name.toLowerCase() === colorName.toLowerCase()).length) {
				message.error('Color is already exist')
			} else {
				console.log("addItem");
				setColorArr([...colorArr, { name: colorName }]);
				props.getColorFormDataAdd({ name: colorName })
				setColorName(false);
			}
		}
	};

	const [speciesArr, setSpeciesArr] = useState([])
	const [speciesName, setSpeciesName] = useState(false);
	const onSpeciesChange = (event) => {
		setSpeciesName(event.target.value);
	};

	const addSpeciesOpt = () => {
		if (speciesName) {

			if (speciesArr.filter(obj => obj.value.toLowerCase() === speciesName.toLowerCase()).length) {
				message.error('Species is already exist')
			} else {
				console.log("addItem");
				setSpeciesArr([...speciesArr, { value: speciesName }]);
				props.getSpeciesFormDataAdd({ value: speciesName })
				setSpeciesName(false);
			}
		}
	};

	const [varietyArr, setVarietyArr] = useState([])
	const [varietyName, setVarietyName] = useState(false);
	const onVarietysChange = (event) => {
		setVarietyName(event.target.value);
	};

	const addVarietyOpt = () => {
		if (varietyName) {
			if (varietyArr.filter(obj => obj.value.toLowerCase() === varietyName.toLowerCase()).length) {
				message.error('Variety is already exist')
			} else {
				console.log("addItem");
				setVarietyArr([...varietyArr, { value: varietyName }]);
				props.getVarietysFormDatAdda({ value: varietyName })
				setVarietyName(false);
			}
		}
	};


	useEffect(() => {
		if (props.fields) {
			setCutArr(props.fields.cuts)
			setShapeArr(props.fields.shapes)
			setColorArr(props.fields.color)
			setSpeciesArr(props.fields.species)
			// setObjectsArr(props.fields.objects)
			setVarietyArr(props.fields.variety)
			setCommentArr(props.fields.comment)
			// setOriginArr(props.fields.origion_gem)
		}
		console.log(props.fields, 'props.fields')
	}, [props.fields])
	const [transparency1, setTransparency1] = useState("");
	const [transparency2, setTransparency2] = useState("");
	const selectRef = useRef(null);
	useEffect(() => {
		if (transparency1 && transparency2) {
			let obj2 = { ...obj };
			obj2.transparency = transparency1 + " to " + transparency2;
			setobj(obj2);
		}
	}, [transparency1, transparency2])
	return (
		<div className="DiamondGradingFrom">
			{!isReported ? (
				<div className="innerGradingForm">
					<Row className="spaceBetween">
						<Col xs={7} sm={7} md={7} lg={7} xl={7}>
							<div className="inputDiv" style={{ marginTop: 10 }}>
								<label>
									<h6>Date</h6>
									<Input
										onChange={(e) => {
											let obj2 = { ...obj };
											obj2.date = e.target.value;
											setobj(obj2);
										}}
										value={dateFormat(obj.date ? obj.date : obj.entry_date)}
										type="date"
									/>
								</label>
							</div>{" "}
						</Col>
						<Col xs={7} sm={7} md={7} lg={7} xl={7}>
							<div className="inputDiv" style={{ marginTop: 10 }}>
								<label>
									<h6>Measurements</h6>
									<div className="wieghtNumber spaceBetween">
										<Input
											onChange={(e) => {
												let obj2 = { ...obj };
												obj2.measurements = e.target.value;
												setobj(obj2);
											}}
											value={obj.measurements}
										/>
										<span>*</span>
										<Input
											onChange={(e) => {
												let obj2 = { ...obj };
												obj2.measurements2 = e.target.value;
												setobj(obj2);
											}}
											value={obj.measurements2}
										/>
										<span>*</span>
										<Input
											onChange={(e) => {
												let obj2 = { ...obj };
												obj2.measurements3 = e.target.value;
												setobj(obj2);
											}}
											value={obj.measurements3}
										/>
									</div>
								</label>
							</div>
						</Col>
						<Col xs={7} sm={7} md={7} lg={7} xl={7}>
							<div className="inputDiv" style={{ marginTop: 10 }}>
								<label>
									<h6>Shape</h6>
									<Select
										showSearch
										onChange={(e) => {
											let obj2 = { ...obj };
											obj2.shape = e;
											setobj(obj2);
										}}
										className="select"
										defaultValue="N/A"
										value={obj.shape}
										dropdownRender={(menu) => (
											<div>
												{menu}
												<Divider style={{ margin: "4px 0" }} />
												<div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
													<Input
														style={{ flex: "auto" }}
														value={shapeName ? shapeName : ""}
														onChange={onShapeChange}
													/>
													{shapeName ? (
														<a
															style={{
																flex: "none",
																padding: "8px",
																display: "block",
																cursor: "pointer"
															}}
															onClick={addShapeOpt}
														>
															<PlusOutlined />
														</a>
													) : null}
												</div>
											</div>
										)}
									>
										{shapeArr.length && shapeArr.map((a, i) => (
											<Option value={a.value}>{a.value}</Option>
										))}
									</Select>
								</label>
							</div>
						</Col>
						<Col xs={7} sm={7} md={7} lg={7} xl={7}>
							<div className="inputDiv" style={{ marginTop: 10 }}>
								<label>
									<h6>Cut</h6>
									<Select
										showSearch
										onChange={(e) => {
											let obj2 = { ...obj };
											obj2.cut = e;
											setobj(obj2);
										}}
										className="select"
										defaultValue="N/A"
										value={obj.cut}
										dropdownRender={(menu) => (
											<div>
												{menu}
												<Divider style={{ margin: "4px 0" }} />
												<div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
													<Input
														style={{ flex: "auto" }}
														value={cutName ? cutName : ""}
														onChange={onCutChange}
													/>
													{cutName ? (
														<a
															style={{
																flex: "none",
																padding: "8px",
																display: "block",
																cursor: "pointer"
															}}
															onClick={addCutOpt}
														>
															<PlusOutlined />
														</a>
													) : null}
												</div>
											</div>
										)}
									>

										{cutArr.length && cutArr.map((a, i) => (
											<Option value={a.value}>{a.value}</Option>
										))}
										<Option value="other">other</Option>
									</Select>
								</label>
							</div>
						</Col>
						<Col xs={7} sm={7} md={7} lg={7} xl={7}>
							<div
								className="inputDiv"
								style={{ display: "flex", alignItems: "flex-end" }}
							>
								<label>
									<h6>Weights</h6>
									<Input
										onChange={(e) => {
											let obj2 = { ...obj };
											obj2.carat_weight = e.target.value;
											setobj(obj2);
										}}
										value={obj.carat_weight}
										autoComplete="on"
									/>
								</label>
								<Select
									style={{ width: 100 }}
									onChange={(e) => {
										let obj2 = { ...obj };
										obj2.weight_unit = e;
										setobj(obj2);
									}}
									className="select"
									defaultValue="CT"
									value={obj.weight_unit}
								>
									<Option value="CT">Carats</Option>
									<Option value="Gm">Grams</Option>
								</Select>
							</div>
						</Col>
						<Col xs={7} sm={7} md={7} lg={7} xl={7}>
							<div className="inputDiv commnetInput" style={{ marginTop: 10 }}>
								<label>
									<h6>Comment</h6>
									<TextArea
										// value={value}
										// onChange={this.onChange}
										placeholder="Controlled autosize"
										autoSize={{ minRows: 3, maxRows: 5 }}
										onChange={(e) => {
											let obj2 = { ...obj };
											obj2.comment = e.target.value;
											setobj(obj2);
										}}
										value={obj.comment}
									/>
								</label>
							</div>
						</Col>
						<Col xs={7} sm={7} md={7} lg={7} xl={7}>
							<div className="inputDiv" style={{ marginTop: 10 }}>
								<label>
									<h6>Color</h6>
									<Select
										showSearch
										onChange={(e) => {
											let obj2 = { ...obj };
											obj2.color = e;
											setobj(obj2);
										}}
										className="select"
										defaultValue=""
										value={obj.color}
										dropdownRender={(menu) => (
											<div>
												{menu}
												<Divider style={{ margin: "4px 0" }} />
												<div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
													<Input
														style={{ flex: "auto" }}
														value={colorName ? colorName : ""}
														onChange={onColorChange}
													/>
													{colorName ? (
														<a
															style={{
																flex: "none",
																padding: "8px",
																display: "block",
																cursor: "pointer"
															}}
															onClick={addColorOpt}
														>
															<PlusOutlined />
														</a>
													) : null}
												</div>
											</div>
										)}
									>
										{colorArr.map((a, i) => {
											return <Option value={a.name}>{a.name}</Option>;
										})}
									</Select>
								</label>
							</div>
						</Col>
						<Col xs={7} sm={7} md={7} lg={7} xl={7}>
							<div className="inputDiv" style={{ marginTop: 10 }}>
								<label>
									<h6>Transparency</h6>
									<Select
										ref={selectRef}
										onChange={(e) => {
											let obj2 = { ...obj };
											obj2.transparency = e;
											setobj(obj2);
											setTransparency1("")
											setTransparency2("")
										}}
										className="select"
										showAction={["click", "focus"]}
										defaultValue="Transparent"
										value={transparency1 && transparency2 ? transparency1 + " to " + transparency2 : obj.transparency}
										dropdownRender={(menu) => (
											<div>
												{menu}
												<Divider style={{ margin: "4px 0" }} />
												<div style={{ display: "flex", flexWrap: "nowrap", justifyContent: "space-between", alignItems: "center", padding: 8 }}>
													<Select
														// placement={"topLeft"}
														onChange={(e) => {
															// let obj2 = { ...obj };
															// obj2.transparency = e;
															// setobj(obj2);
															selectRef.current.focus()
															// console.log()
															setTransparency1(e)
														}}
														className="select"
														style={{ width: "46%" }}
														defaultValue="Transparent"
														value={transparency1}
													>
														<Option value="Transparent">Transparent</Option>
														<Option value="Semi-Transparent">Semi-Transparent</Option>
														<Option value="Translucent">Translucent</Option>
														<Option value="Semi-Translucent">Semi-Translucent</Option>
														<Option value="Opaque">Opaque</Option>
													</Select>
													to
													<Select
														onChange={(e) => {
															// let obj2 = { ...obj };
															// obj2.transparency = e;
															// setobj(obj2);
															selectRef.current.focus()
															setTransparency2(e)
														}}
														className="select"
														defaultValue="Transparent"
														style={{ width: "46%" }}
														value={transparency2}
													>
														<Option value="Transparent">Transparent</Option>
														<Option value="Semi-Transparent">Semi-Transparent</Option>
														<Option value="Translucent">Translucent</Option>
														<Option value="Semi-Translucent">Semi-Translucent</Option>
														<Option value="Opaque">Opaque</Option>
													</Select>
												</div>
											</div>
										)}
									>
										<Option value="Transparent">Transparent</Option>
										<Option value="Semi-Transparent">Semi-Transparent</Option>
										<Option value="Translucent">Translucent</Option>
										<Option value="Semi-Translucent">Semi-Translucent</Option>
										<Option value="Opaque">Opaque</Option>
										<Option value="">N/A</Option>
										{transparency1 && transparency2 ? <Option value={transparency1 + " to " + transparency2}>{transparency1 + " to " + transparency2}</Option> : ""}
									</Select>
								</label>
							</div>
						</Col>
						<Col xs={7} sm={7} md={7} lg={7} xl={7}>
							<div className="inputDiv" style={{ marginTop: 10 }}>
								<label>
									<h6>Species</h6>
									<Select
										showSearch
										onChange={(e) => {
											let obj2 = { ...obj };
											obj2.species = e;
											setobj(obj2);
										}}
										className="select"
										defaultValue="N/A"
										value={obj.species}
										dropdownRender={(menu) => (
											<div>
												{menu}
												<Divider style={{ margin: "4px 0" }} />
												<div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
													<Input
														style={{ flex: "auto" }}
														value={speciesName ? speciesName : ""}
														onChange={onSpeciesChange}
													/>
													{speciesName ? (
														<a
															style={{
																flex: "none",
																padding: "8px",
																display: "block",
																cursor: "pointer"
															}}
															onClick={addSpeciesOpt}
														>
															<PlusOutlined />
														</a>
													) : null}
												</div>
											</div>
										)}
									>

										{speciesArr.length && speciesArr.map((a, i) => (
											<Option value={a.value}>{a.value}</Option>
										))}
										<Option value="other">other</Option>
									</Select>
								</label>
							</div>
						</Col>
						<Col xs={7} sm={7} md={7} lg={7} xl={7}>
							<div className="inputDiv" style={{ marginTop: 10 }}>
								<label>
									<h6>Variety </h6>
									<Select
										showSearch
										onChange={(e) => {
											let obj2 = { ...obj };
											obj2.variety = e;
											setobj(obj2);
										}}
										className="select"
										defaultValue="N/A"
										value={obj.variety}
										dropdownRender={(menu) => (
											<div>
												{menu}
												<Divider style={{ margin: "4px 0" }} />
												<div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
													<Input
														style={{ flex: "auto" }}
														value={varietyName ? varietyName : ""}
														onChange={onVarietysChange}
													/>
													{varietyName ? (
														<a
															style={{
																flex: "none",
																padding: "8px",
																display: "block",
																cursor: "pointer"
															}}
															onClick={addVarietyOpt}
														>
															<PlusOutlined />
														</a>
													) : null}
												</div>
											</div>
										)}
									>
										{varietyArr.length && varietyArr.map((a, i) => (
											<Option value={a.value}>{a.value}</Option>
										))}
									</Select>
								</label>
							</div>
						</Col>
						<Col xs={7} sm={7} md={7} lg={7} xl={7}>
							<div className="inputDiv" style={{ marginTop: 10 }}>
								<label>
									<h6>Product Image</h6>
									<div className="imageBox">
										<div
											className="box"
											style={{
												backgroundImage: `url('${image
													? image
													: obj.fpath
														? obj.fpath
														: obj.product_pic
															? obj.product_pic
															: upload
													}')`
											}}
										></div>
										<Input
											onChange={(e) => handleChange(e, "image")}
											type="file"
											placeholder="upload"
										/>
									</div>
								</label>
							</div>
						</Col>
						<Col xs={7} sm={7} md={7} lg={7} xl={7}></Col>
					</Row>
					{isEdit ? (
						<Button onClick={() => update()} type="primary" disabled={props.managerPanelLoading}>
							{props.managerPanelLoading ? <div className="loading1"></div> : "Update"}
						</Button>
					) : (
						<Button onClick={() => save()} type="primary" disabled={props.managerPanelLoading}>
							{props.managerPanelLoading ? <div className="loading1"></div> : "Save"}
						</Button>
					)}

					<Button
						onClick={() => props.history.goBack()}
						type="primary"
						style={{ margin: "0px 10px" }}
					>
						Cancel
					</Button>
				</div>
			) : (
				<h2>Already Made</h2>
			)}
		</div>
	);
}

const mapStateToProps = (store) => ({
	permission: store.users.permission,
	singleItemEntry: store.dataEntry.singleItemEntry,
	singleReportEntry: store.dataEntry.singleReportEntry,
	managerPanelLoading: store.managerPanel.managerPanelLoading,
	fields: store.FormValues

});

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			setCertificate,
			getSingleItem,
			getSingleReport,
			updateCertificate,
			getSingleItemConsultation,
			getColorFormDataAdd,
			getCutFormDataAdd,
			getSpeciesFormDataAdd,
			getCuttingStyleFormDataAdd,
			getOrigionGemFormDataAdd,
			getGridlesFormDataAdd,
			getRemarksFormDatAdda,
			getVarietysFormDatAdda,
			getShapesFormDatAdda,
			getObjectsFormDataAdd,
			getSingleItemSuccess,
			getSingleReportSuccess
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(SmallGemstoneForm);
