import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Input, Space, DatePicker, Button } from "antd";
import { Col, Row, Select } from "antd";
import "./../customerReport.scss";
import "./itemReports.scss";
import RecieptReports from "./Reports/RecieptReports";
import CustomerSummary from "./Reports/CustomerSummary";
import DirectCash from "./Reports/directCash/DirectCash";
import CashRecieved from "./Reports/cashRecieved/CashRecieved";
import ServiceReport from "./Reports/services/ServiceReport";
import ExpenseReport from "./Reports/expenses/ExpenseReport";
import { getBranch } from "../../../modules/actions/branchActions";
import moment from "moment";

const { Search } = Input;

const { Option } = Select;

function ItemReport(props) {
	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());

	const [selectedReports, setSelectedReports] = useState("Receipt_Generated");
	const [SortType, setSortType] = useState("Default");
	const [serviceObj, setServiceObj] = useState();
	const [selectedService, setSelectedService] = useState("");
	const [keyword, setKeyWord] = useState("");
	const [searchEnter, setSearchEnter] = useState(false);
	const [multiVal, setMultiVal] = useState("");
	const [selectedBranch, setSelectedBranch] = useState("");
	const [searchBool, setSearchBool] = useState(false)
	const [expenseNames, setExpenseNames] = useState([]);
	const [filterCustomers, setFilteredCustomer] = useState([])
	const [selectedCustomerBranch, setSelectedCustomerBranch] = useState('')
	const [searchCustomer, setSearchCustomer] = useState('')
	const customerSearch = (reset) => {
		if (reset) {
			setSelectedCustomerBranch('')
			setSearchCustomer('')
			setFilteredCustomer(props.allCustomers)
		} else {
			if (selectedCustomerBranch && !searchCustomer) {
				let arr = [...props.allCustomers]
				console.log(selectedCustomerBranch, 'selectedCustomerBranch')
				let filteredArr = arr.filter(obj => obj.customerId.toLowerCase().includes(selectedCustomerBranch.toLowerCase()))
				setFilteredCustomer(filteredArr)
			} else if (searchCustomer && !selectedCustomerBranch) {
				let arr = [...props.allCustomers]
				console.log(selectedCustomerBranch, 'selectedCustomerBranch')
				let filteredArr = arr.filter(obj => obj.customerName.toLowerCase().includes(searchCustomer.toLowerCase()) || obj.customerId.toLowerCase().includes(searchCustomer.toLowerCase()))
				setFilteredCustomer(filteredArr)
			} else if (searchCustomer && selectedCustomerBranch) {
				let arr = [...props.allCustomers]
				console.log(selectedCustomerBranch, 'selectedCustomerBranch')
				let filteredArr = arr.filter(obj => obj.customerName.toLowerCase().includes(searchCustomer.toLowerCase()) && obj.customerId.toLowerCase().includes(selectedCustomerBranch.toLowerCase()))
				setFilteredCustomer(filteredArr)
			} else if (!searchCustomer && !selectedCustomerBranch) {
				setFilteredCustomer(props.allCustomers)
			}
		}

		// let filteredArr =  arr.map((a,i)=>{
		// 	if(selectedCustomerBranch === '')
		// })
	}

	useEffect(() => {
		if (props.allCustomers) {
			customerSearch()
		}
	}, [props.allCustomers])
	useEffect(() => {
		setSearchBool(!searchBool)
	}, [])
	useEffect(() => {
		console.log(startDate)
		console.log(endDate)
	}, [startDate,
		endDate])

	useEffect(() => {
		console.log(props.branch, "props.branch");
		if (props.userPermissions.userType != "Admin" && props.userPermissions.branchCode) {
			setSelectedBranch(props.userPermissions.branchCode);
		} else {
			setSelectedBranch("KHI");
		}
		if (props.userPermissions.userType == "Manager") {
			setStartDate(new Date("1/1/1970, 12:00:00 AM"));
		}
	}, []);

	useEffect(() => {
		setServiceObj(props.formData.DeliveryService);
	}, [props.formData]);

	useEffect(() => {
		let arr = [];
		if (props.expenseNames) {
			for (let i = 0; i < props.expenseNames.length; i++) {
				arr.push(props.expenseNames[i].name);
			}
			setExpenseNames(arr);
		}
	}, [props.expenseNames]);
	useEffect(() => {
		props.getBranch(props.permission.branchCode);
		console.log("keyworddddddddddddddddddd", keyword);
	}, [props.permission.branchCode]);

	return (
		<div className="ItemReport">
			<div>
				<Row className="">

					<Col xs={6} sm={6} md={6} lg={6} xl={6}>
						<Select
							defaultValue={selectedReports}
							onChange={(e) => setSelectedReports(e)}
							className="select w100P"
						>
							<Option value="Customers">Customers</Option>
							<Option value="Cash_Received">Cash Recieved</Option>
							<Option value="Service">Services</Option>

							{props.userPermissions.reciept_generated_view && (
								<Option value="Receipt_Generated">Receipt Generated</Option>
							)}

							<Option value="Receipt_Pending">Receipt Pending</Option>
							<Option value="Receipt_No">Receipt No</Option>
							<Option value="Direct Cash Recieved">Direct Cash Recieved</Option>
							<Option value="Expenses">Expenses</Option>
						</Select>
					</Col>
					<Col xs={18} sm={18} md={18} lg={18} xl={18}>
						{selectedReports.toLowerCase() != 'customers' ?

							// {selectedReports.toLowerCase() != 'customers' ?}
							<Row wrap className="filterRow" align="middle">
								<span className="filterText">
									Filter :
								</span>

								<Col xs={4} sm={4} md={4} lg={4} xl={4}>
									<Space direction="vertical" className="w100P">
										<DatePicker
											value={moment(startDate)}
											// value={null}
											onChange={(e) => setStartDate(e)}
											className="w100P"
											allowClear={false}
											disabled={selectedReports.toLowerCase() != 'receipt_no' ? false : true}

										/>
									</Space>
								</Col>
								<Col xs={4} sm={4} md={4} lg={4} xl={4}>
									<Space direction="vertical" className="w100P">
										<DatePicker
											value={moment(endDate)}
											onChange={(e) => setEndDate(e)}
											className="w100P"
											allowClear={false}
											disabled={selectedReports.toLowerCase() != 'receipt_no' ? false : true}
										/>
									</Space>
								</Col>
								{props.userPermissions.admin_panel && (
									<Col xs={4} sm={4} md={4} lg={4} xl={4}>
										<Select
											defaultValue={selectedBranch}
											onChange={(e) => setSelectedBranch(e)}
											className="select w100P"
										>
											{props.branch
												? props.branch.map((a, i) => {
													return <Option value={a.branchCode}>{a.branchName}</Option>;
												})
												: null}
										</Select>
									</Col>
								)}
								{selectedReports.toLowerCase() === "receipt_generated" ? (
									<Col xs={6} sm={6} md={6} lg={6} xl={6}>
										<Space direction="vertical" className="w100P">
											<Search
												onKeyDown={(ev) => {
													if (ev.keyCode === 13) {
														setSearchBool(!searchBool)

													}
												}}
												onSearch={() => setSearchEnter(searchEnter ? false : true)}
												onChange={(e) => { setKeyWord(e.target.value); }}
												placeholder="input search text"
												className="w100P"
												style={{ width: 2 }}
											/>
										</Space>
									</Col>
								) : selectedReports.toLowerCase() === "receipt_pending" ? (
									<Col xs={6} sm={6} md={6} lg={6} xl={6}>
										<Space direction="vertical" className="w100P">
											<Search
												onKeyDown={(ev) => {
													if (ev.keyCode === 13) {
														setSearchBool(!searchBool)

													}
												}}
												onSearch={() => setSearchEnter(searchEnter ? false : true)}
												onChange={(e) => setKeyWord(e.target.value)}
												placeholder="input search text"
												className="w100P"
												style={{ width: 2 }}
											/>
										</Space>
									</Col>
								) : selectedReports.toLowerCase() === "receipt_no" ? (
									<Col xs={6} sm={6} md={6} lg={6} xl={6}>
										<Space direction="vertical" className="w100P">
											<Search
												onKeyDown={(ev) => {
													if (ev.keyCode === 13) {
														setSearchBool(!searchBool)

													}
												}}
												onSearch={() => setSearchEnter(searchEnter ? false : true)}
												onChange={(e) => setKeyWord(e.target.value)}
												placeholder="Receipt No"
												className="w100P"
												style={{ width: 2 }}
											/>
										</Space>
									</Col>
								) :
									selectedReports.toLowerCase() === 'customers' ?
										<>
											{/* <Col xs={6} sm={6} md={6} lg={6} xl={6}>
									<Space direction="vertical" className="w100P">
										<Search onSearch={() => setSearchEnter(searchEnter ? false : true)} onChange={(e) => setKeyWord(e.target.value)} placeholder="input search text" className="w100P" style={{ width: 2 }} />
									</Space>
									<Select
							defaultValue={SortType}
							onChange={(e) => setSortType(e)}
							className="select w100P"
						>
							<Option value="Default">Default</Option>
							<Option value="asc">Ascending</Option>
							<Option value="dsc">Decending</Option>
						</Select>
								</Col> */}
										</>
										:
										selectedReports.toLowerCase() === "service" ? (
											<Col xs={6} sm={6} md={6} lg={6} xl={6}>
												<Select
													onChange={(e) => setSelectedService(e)}
													className="select w100P"
													placeholder="Select Service"
												>
													<Option value="">All</Option>
													{serviceObj &&
														serviceObj.map((a, i) => {
															return <Option value={a.name}>{a.name}</Option>;
														})}
												</Select>
											</Col>
										) : selectedReports.toLowerCase() === "expenses" ? (
											<>
												<Col xs={6} sm={6} md={6} lg={6} xl={6}>
													<Select
														mode="multiple"
														allowClear
														style={{ width: "100%" }}
														placeholder="Please select"
														onChange={(e) => {
															setMultiVal(e.join(","));
														}}
													>


														{expenseNames.map((a, i) => {
															return (
																<Option key={i + 1} value={a}>
																	{a}
																</Option>
															);
														})}
													</Select>
												</Col>
												<Col className="expenseSearch" xs={6} sm={6} md={6} lg={6} xl={6}>
													<Space direction="vertical" className="w100P">
														<Search
															onKeyDown={(ev) => {
																if (ev.keyCode === 13) {
																	setSearchBool(!searchBool)

																}
															}} onSearch={() => setSearchEnter(searchEnter ? false : true)} onChange={(e) => setKeyWord(e.target.value)} placeholder="input search text" className="w100P" style={{ width: 2 }} />
													</Space>
												</Col>
											</>

										) : null}
								<Button style={{
									background: "rgb(0, 21, 41)",
									borderColor: "rgb(0, 21, 41)",
									marginRight: "5px",
									color: "white",
									borderRadius: "20px"
								}}
									disabled={props.reportsLoading}
									onClick={() => {
										setSearchBool(!searchBool)
									}}
								>Search</Button>
							</Row>
							:
							<Row wrap className="filterRow" align="middle">
								<Col xs={6} sm={6} md={6} lg={6} xl={6}>
									<Space direction="vertical" className="w100P">
										<Search onKeyDown={(ev) => {
											if (ev.keyCode === 13) {
												customerSearch()
											}
										}} onChange={(e) => setSearchCustomer(e.target.value)} value={searchCustomer} placeholder="input search text" className="w100P" style={{ width: 2 }} />
									</Space>
								</Col>
								<Col xs={4} sm={4} md={4} lg={4} xl={4}>
									<Select
										defaultValue={selectedCustomerBranch}
										onChange={(e) => setSelectedCustomerBranch(e)}
										className="select w100P"
										// value="sselect"
										value={selectedCustomerBranch}
									>
										<Option key={0} value={''}>
											Default
										</Option>
										{props.branch
											? props.branch.map((a, i) => {
												return <Option value={a.branchCode}>{a.branchName}</Option>;
											})
											: null}

									</Select>
								</Col>
								<Button style={{
									background: "rgb(0, 21, 41)",
									borderColor: "rgb(0, 21, 41)",
									marginRight: "5px",
									color: "white",
									borderRadius: "20px"
								}}
									onClick={() => {
										customerSearch(false)
									}}
								>Search</Button>
								<Button
									type="primary"
									onClick={() => {
										customerSearch(true)
									}}
									style={{ background: "#f50057", borderColor: "#f50057" }}
								>
									Clear
								</Button>
							</Row>
						}
					</Col>
				</Row>

				{
					selectedReports.split("_").join(" ").toLowerCase().match("receipt") ? (
						<RecieptReports
							keyword={keyword}
							searchEnter={searchEnter}
							selectedReports={selectedReports}
							startDate={startDate}
							endDate={endDate}
							selectedBranch={selectedBranch}
							searchBool={searchBool}
						/>
					) : selectedReports.split("_").join(" ").toLowerCase() === "customers" ? (
						<CustomerSummary
							keyword={keyword}
							searchEnter={searchEnter}
							selectedReports={selectedReports}
							startDate={startDate}
							endDate={endDate}
							selectedBranch={selectedBranch}
							sortType={SortType}
							searchBool={searchBool}
							allCustomersData={filterCustomers}
						/>
					) : selectedReports.split("_").join(" ").toLowerCase() === "direct cash recieved" ? (
						<DirectCash
							keyword={keyword}
							searchEnter={searchEnter}
							selectedReports={selectedReports}
							startDate={startDate}
							endDate={endDate}
							selectedBranch={selectedBranch}
							searchBool={searchBool}
						/>
					) : selectedReports.split("_").join(" ").toLowerCase() === "cash received" ? (
						<CashRecieved
							keyword={keyword}
							searchEnter={searchEnter}
							selectedReports={selectedReports}
							startDate={startDate}
							endDate={endDate}
							selectedBranch={selectedBranch}
							searchBool={searchBool}
						/>
					) : selectedReports.split("_").join(" ").toLowerCase() === "service" ? (
						<ServiceReport
							selectedService={selectedService}
							startDate={startDate}
							endDate={endDate}
							selectedReports={selectedReports}
							selectedBranch={selectedBranch}
							searchBool={searchBool}
						/>
					) : selectedReports.split("_").join(" ").toLowerCase() === "expenses" ? (
						<ExpenseReport
							multiVal={multiVal}
							keyword={keyword}
							startDate={startDate}
							endDate={endDate}
							searchEnter={searchEnter}
							selectedReports={selectedReports}
							searchBool={searchBool}
							selectedBranch={selectedBranch}
						/>
					) : null
				}
			</div >
		</div >
	);
}

const mapStateToProps = (store) => {
	return {
		userPermissions: store.authentication.userPermissions,
		formData: store.FormData,
		branch: store.branchStore.branch,
		expenseNames: store.reports.expenseNames,
		permission: store.users.permission,
		reportsLoading: store.reports.reportsLoading,
		allCustomers: store.customers.allCustomers,
	};
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			getBranch
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(ItemReport);
