import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Input, Select, Col, Row, Upload, message, InputNumber, Checkbox, Button, Divider } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import Bruise from "./../../../Images/symbols/Bruise.jpg";
import Cavity from "./../../../Images/symbols/Cavity.jpg";
import Chip from "./../../../Images/symbols/Chip.jpg";
import Cleavage from "./../../../Images/symbols/Cleavage.jpg";
import Crystal from "./../../../Images/symbols/Crystal.jpg";
import ExtraFacet from "./../../../Images/symbols/ExtraFacet.jpg";
import Feather from "./../../../Images/symbols/Feather.jpg";
import GrainCenter from "./../../../Images/symbols/GrainCenter.jpg";
import IndentedNatural from "./../../../Images/symbols/IndentedNatural.jpg";
import InternalGraining from "./../../../Images/symbols/InternalGraining.jpg";
import Knot from "./../../../Images/symbols/Knot.jpg";
import Natural from "./../../../Images/symbols/Natural.jpg";
import Needle from "./../../../Images/symbols/Needle.jpg";
import PinPoint from "./../../../Images/symbols/PinPoint.jpg";
import TwinningWisp from "./../../../Images/symbols/TwinningWisp.jpg";
import { dateFormat } from "../../../common/common";
import {
	createImageFile,
	setCertificate,
	updateCertificate
} from "../../../modules/actions/managerPanelActions";
import upload from "./../../../Images/upload.png";
import { getSingleItem, getSingleReport, getSingleItemConsultation } from "../../../modules/actions/dataEntryActions";
import { getColorFormDataAdd, getCuttingStyleFormDataAdd, getObjectsFormDataAdd, getShapesFormDatAdda, getSpeciesFormDataAdd, getVarietysFormDatAdda } from "../../../modules/actions/FormValues";
const { TextArea } = Input;
const { Option } = Select;

function getBase64(file) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = (error) => reject(error);
	});
}

function MasterColoredForm(props) {
	const [obj, setobj] = useState({
		branchCode: props.permission.branchCode,
		relatedEntryNo: "",
		itemId: "",
		Id_of_temp_data: "",
		serial_number: "",
		certificate_number: "",
		color_gem: "",
		weight: "",
		length_gem: "",
		width_gem: "",
		depth_gem: "",
		description: "",
		comments_gem: "",
		species_gem: "",
		variety_gem: "",
		picture_id: "",
		cart_id: "",
		path: "",
		ext: "",
		Fpath: "",
		columnname: "",
		path2: "",
		ext3: "",
		bpath: "",
		customername: "",
		treatment: "",
		Customer_Number: "",
		eptyp_temp: "",
		Number_Of_Diamonds: "",
		Total_Dia_Weight: "",
		Shap_And_Cut: "",
		DiamondCut: "",
		Diamond_Color: "",
		number: "",
		filename: "",
		date: dateFormat(new Date()),
		shape: "",
		cut: "",
		measurement_mm: "",
		measurements: "",
		measurements2: "",
		measurements3: "",
		measurement_unit: "",
		depth: "",
		table: "",
		gridle: "",
		gridle2: "",
		gridle3: "",
		crown_angle: "",
		pavilion_depth: "",
		culet: "",
		polish: "",
		symmetry: "",
		flourescence: "",
		flourescenceColor: "",
		carat_weight: "",
		weight_unit: "",
		color: "",
		clarity: "",
		cut_grade: "",
		entry_date: dateFormat(new Date()),
		comment: "",
		key_to_symbol: "",
		object: "",
		transparency: "",
		species: "",
		variety: "",
		report_id: 7,
		report_page: "",
		remarks: "",
		finish: "",
		diamond1: "",
		diamond2: "",
		diamond3: "",
		diamond4: "",
		diamond5: "",
		key_symbol1: "",
		key_symbol2: "",
		key_symbol3: "",
		key_symbol4: "",
		key_symbol5: "",
		key_symbol6: "",
		key_symbol7: "",
		key_symbol8: "",
		key_symbol9: "",
		key_symbol10: "",
		key_symbol11: "",
		key_symbol12: "",
		key_symbol13: "",
		key_symbol14: "",
		key_symbol15: "",
		key_symbol16: "",
		key_symbol17: "",
		key_symbol18: "",
		disabled_key_symbol: "",
		origion_gem: "",
		pavilion_angle_d: "",
		color_grade: "",
		clarity_grade: "",
		Enhancements: "",
		RI: "",
		RI1: "",
		Polariscope: "",
		uv_flourescence: "",
		micro_evidence: "",
		cutting_style: "",
		microscope: "",
		FTIR: "",
		UV: "",
		Refractometer: "",
		Lamp: "",
		XRF: "",
		uv_flourescence1: ""
	});

	const [runOn, setRunOn] = useState(false);

	const [isReported, setIsReported] = useState(false);
	const [imageUrl, setImageUrl] = useState(false);
	const [image, setImage] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	function handleChange(e) {
		setImageUrl(e.target.files[0]);
		getBase64(e.target.files[0]).then((data) => {
			setImage(data);
		});
	}
	useEffect(() => {
		var url = new URL(window.location);
		let entryNo = url.searchParams.get("entryNo");
		let id = url.searchParams.get("id");
		let editId = url.searchParams.get("editId");
		let consultation = url.searchParams.get("consultation");

		if (editId) {
			setIsEdit(true);
			props.getSingleReport(editId, props.permission.branchCode);
		} else {
			let objj = { ...obj };
			objj.relatedEntryNo = entryNo;
			objj.itemId = Number(id);
			objj.entry_date = dateFormat(new Date());
			objj.date = dateFormat(new Date());
			if (consultation === '1') {
				props.getSingleItemConsultation(id, props.permission.branchCode);
			} else {
				props.getSingleItem(id, props.permission.branchCode);
			}
			setobj(objj);
		}
	}, []);
	const plainOptions2 = [
		"Microscope",
		"FTIR",
		"UV-VIS/NIR",
		"XRF",
		"Refractometer",
		"Polariscope",
		"UV"
	];
	console.log(obj.relatedEntryNo);
	useEffect(() => {
		console.log("runnnnnnn");
		if (props.singleItemEntry) {
			var url = new URL(window.location);
			let objj = obj;
			objj.carat_weight = Number(props.singleItemEntry.weight);
			objj.color = props.singleItemEntry.color;
			if (props.singleItemEntry.report && props.singleItemEntry.report.length) {
				setIsReported(true);
			} else {
				setIsReported(false);
			}
			setRunOn(runOn ? false : true);
			setobj(objj);
		}
		if (props.singleReportEntry) {
			console.log(props.singleReportEntry, "runnnn");
			if (props.singleReportEntry.relatedEntryNo) {
				console.log("no old entry no");
				setobj(props.singleReportEntry);
			} else {
				props.singleReportEntry.relatedEntryNo = "oldData";
				setobj(props.singleReportEntry);
			}
		}
	}, [props.singleItemEntry, props.singleReportEntry]);

	function backFun() {
		props.history.goBack();
	}
	const save = () => {
		var url = new URL(window.location);

		let entryNo = url.searchParams.get("entryNo");
		let data = new FormData();
		for (const property in obj) {
			if (obj[property]) {
				data.append(property, obj[property]);
			} else if (property === "relatedEntryNo" && !obj[property] && entryNo) {
				data.append(property, entryNo);
			}
		}
		let entryId = url.searchParams.get("id");
		data.append("itemId", Number(entryId));
		console.log(entryId);

		data.append("product_pic", imageUrl);
		props.setCertificate(data, props.permission.branchCode, clearState, backFun);
	};

	const update = () => {
		let data = new FormData();
		let imageFile = [];
		for (const property in obj) {
			if (property === "product_pic") {
				if (obj[property]) {

					createImageFile(imageUrl ? imageUrl : obj[property]).then((result) => {
						// imageFile.push(result);

						data.append(property, result);
					});
				}
				else {

					createImageFile(imageUrl ? imageUrl : obj[property]).then((result) => {
						// imageFile.push(result);

						data.append(property, result);
					});
				}
			}

			else if (property.toLowerCase().match("fpath")) {
				data.append(property, '')
				// if(obj[property]){

				// createImageFile(imageUrl ? imageUrl : obj[property]).then((result) => {
				// 	// imageFile.push(result);

				// 	data.append(property, result);
				// });
				// }
				// else{

				// createImageFile(imageUrl ? imageUrl : obj[property]).then((result) => {
				// 	// imageFile.push(result);

				// 	data.append(property, result);
				// });
				// }				// data.set(property, "");
			}
			else if (obj[property]) {
				data.append(property, obj[property]);
			}
		}

		props.updateCertificate(data, props.permission.branchCode, clearState, obj.id, backFun);
	};

	const clearState = () => {
		let objj = obj;
		for (let keys in objj) {
			objj[keys] = "";
		}
		setobj(objj);
		setRunOn(runOn ? false : true);
	};

	const [shapeArr, setShapeArr] = useState([])
	const [shapeName, setShapeName] = useState(false);
	const onShapeChange = (event) => {
		setShapeName(event.target.value);
	};

	const addShapeOpt = () => {
		if (shapeName) {
			if (shapeArr.filter(obj => obj.value.toLowerCase() === shapeName.toLowerCase()).length) {
				message.error('Shape is already exist')
			} else {
				console.log("addItem");
				setShapeArr([...shapeArr, { value: shapeName }]);
				props.getShapesFormDatAdda({ value: shapeName })
				setShapeName(false);
			}
		}
	};

	const [commentArr, setCommentArr] = useState([])
	const [commentName, setCommentName] = useState(false);
	const onCommentChange = (event) => {
		setCommentName(event.target.value);
		let obj2 = { ...obj };
		obj2.comment = event.target.value;
		setobj(obj2);
	};


	const addCommentOpt = () => {
		if (commentName) {
			console.log("addItem");
			setCommentArr([...commentArr, commentName]);
			setCommentName(false);
		}
	};

	const [cuttingStyleArr, setCuttingStyleArr] = useState([])
	const [cuttingStyleName, setCuttingStyleName] = useState(false);
	const onCuttingStyleChange = (event) => {
		setCuttingStyleName(event.target.value);
	};

	const addCuttingStyleOpt = () => {
		if (cuttingStyleName) {
			if (cuttingStyleArr.filter(obj => obj.value.toLowerCase() === cuttingStyleName.toLowerCase()).length) {
				message.error('Cut Style is already exist')
			} else {
				console.log("addItem");
				setCuttingStyleArr([...cuttingStyleArr, { value: cuttingStyleName }]);
				props.getCuttingStyleFormDataAdd({ value: cuttingStyleName })
				setCuttingStyleName(false);
			}
		}
	};

	const [objectArr, setObjectsArr] = useState([])
	const [objectName, setObjectsName] = useState(false);
	const onObjectsChange = (event) => {
		setObjectsName(event.target.value);
	};

	const addObjectOpt = () => {
		if (objectName) {
			if (objectArr.filter(obj => obj.value.toLowerCase() === objectName.toLowerCase()).length) {
				message.error('Object is already exist')
			} else {
				console.log("addItem");
				setObjectsArr([...objectArr, { value: objectName }]);
				props.getObjectsFormDataAdd({ value: objectName })
				setObjectsName(false);
			}
		}
	};

	const [colorArr, setColorArr] = useState([])
	const [colorName, setColorName] = useState(false);
	const onColorChange = (event) => {
		setColorName(event.target.value);
	};

	const addColorOpt = () => {
		if (colorName) {
			if (colorArr.filter(obj => obj.name.toLowerCase() === colorName.toLowerCase()).length) {
				message.error('Color is already exist')
			} else {
				console.log("addItem");
				setColorArr([...colorArr, { name: colorName }]);
				props.getColorFormDataAdd({ name: colorName })
				setColorName(false);
			}
		}
	};

	const [speciesArr, setSpeciesArr] = useState([])
	const [speciesName, setSpeciesName] = useState(false);
	const onSpeciesChange = (event) => {
		setSpeciesName(event.target.value);
	};

	const addSpeciesOpt = () => {
		if (speciesName) {
			if (speciesArr.filter(obj => obj.value.toLowerCase() === speciesName.toLowerCase()).length) {
				message.error('Species is already exist')
			} else {
				console.log("addItem");
				setSpeciesArr([...speciesArr, { value: speciesName }]);
				props.getSpeciesFormDataAdd({ value: speciesName })
				setSpeciesName(false);
			}
		}
	};

	const [varietyArr, setVarietyArr] = useState([])
	const [varietyName, setVarietyName] = useState(false);
	const onVarietysChange = (event) => {
		setVarietyName(event.target.value);
	};

	const addVarietyOpt = () => {
		if (varietyName) {
			if (varietyArr.filter(obj => obj.value.toLowerCase() === varietyName.toLowerCase()).length) {
				message.error('Variety is already exist')
			} else {
				console.log("addItem");
				setVarietyArr([...varietyArr, { value: varietyName }]);
				props.getVarietysFormDatAdda({ value: varietyName })
				setVarietyName(false);
			}
		}
	};
	const selectRef = useRef(null);


	useEffect(() => {
		if (props.fields) {
			// setCutArr(props.fields.cuts)
			setShapeArr(props.fields.shapes)
			setCuttingStyleArr(props.fields.cutting_style)

			setColorArr(props.fields.color)
			setSpeciesArr(props.fields.species)
			setObjectsArr(props.fields.objects)
			setVarietyArr(props.fields.variety)
			setOriginArr(props.fields.origion_gem)
			setCommentArr(props.fields.comment)


		}
		console.log(props.fields, 'props.fields')
	}, [props.fields])
	const [transparency1, setTransparency1] = useState("");
	const [transparency2, setTransparency2] = useState("");
	useEffect(() => {
		if (transparency1 && transparency2) {
			let obj2 = { ...obj };
			obj2.transparency = transparency1 + " to " + transparency2;
			setobj(obj2);
		}
	}, [transparency1, transparency2])

	const [originArr, setOriginArr] = useState([])
	const [originName, setOriginName] = useState(false);
	const onOriginsChange = (event) => {
		setOriginName(event.target.value);
	};

	const addOriginOpt = () => {
		if (originName) {
			if (originArr.filter(obj => obj.value.toLowerCase() === originName.toLowerCase()).length) {
				message.error('Origin is already exist')
			} else {
				console.log("addItem");
				setOriginArr([...originArr, originName]);
				setOriginName(false);
			}
		}
	};


	return (
		<div className="DiamondGradingFrom">
			<div className="innerGradingForm">
				<Row className="spaceBetween">
					<Col xs={7} sm={7} md={7} lg={7} xl={7}>
						<div className="inputDiv">
							<label>
								<h6>Date</h6>
								<Input
									onChange={(e) => {
										let obj2 = { ...obj };
										obj2.date = e.target.value;
										setobj(obj2);
									}}
									value={dateFormat(obj.date ? obj.date : obj.entry_date)}
									type="date"
								/>
							</label>
						</div>{" "}
					</Col>
					<Col xs={7} sm={7} md={7} lg={7} xl={7}>
						<div className="inputDiv">
							<label>
								<h6 onClick={() => console.log(obj)}>Measurements</h6>
								<div className="weightDiv">
									<div className="wieghtNumber spaceBetween">
										<Input
											onChange={(e) => {
												let obj2 = { ...obj };
												obj2.measurements = e.target.value;
												setobj(obj2);
											}}
											value={obj.measurements}
										/>
										<span>*</span>
										<Input
											onChange={(e) => {
												let obj2 = { ...obj };
												obj2.measurements2 = e.target.value;
												setobj(obj2);
											}}
											value={obj.measurements2}
										/>
										<span>*</span>
										<Input
											onChange={(e) => {
												let obj2 = { ...obj };
												obj2.measurements3 = e.target.value;
												setobj(obj2);
											}}
											value={obj.measurements3}
										/>
									</div>
									<select
										className="select mcMesSelect"
										defaultValue="mm"
										onChange={(e) => {
											let obj2 = { ...obj };
											obj2.measurement_unit = e.target.value;
											setobj(obj2);
										}}
										value={obj.measurement_unit}
									>
										<option value="mm">Milimeter</option>
										<option value="cm">Centimeter</option>
									</select>
								</div>
							</label>
						</div>
					</Col>
					<Col xs={7} sm={7} md={7} lg={7} xl={7}>
						<div className="inputDiv">
							<label>
								<h6>Shape</h6>
								<Select
									showSearch
									onChange={(e) => {
										let obj2 = { ...obj };
										obj2.shape = e;
										setobj(obj2);
									}}
									className="select"
									defaultValue="N/A"
									value={obj.shape}
									dropdownRender={(menu) => (
										<div>
											{menu}
											<Divider style={{ margin: "4px 0" }} />
											<div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
												<Input
													style={{ flex: "auto" }}
													value={shapeName ? shapeName : ""}
													onChange={onShapeChange}
												/>
												{shapeName ? (
													<a
														style={{
															flex: "none",
															padding: "8px",
															display: "block",
															cursor: "pointer"
														}}
														onClick={addShapeOpt}
													>
														<PlusOutlined />
													</a>
												) : null}
											</div>
										</div>
									)}
								>
									{shapeArr.length && shapeArr.map((a, i) => (
										<Option value={a.value}>{a.value}</Option>
									))}
								</Select>
							</label>
						</div>
					</Col>
					<Col xs={7} sm={7} md={7} lg={7} xl={7}>
						<div className="inputDiv">
							<label>
								<h6>Cutting Style</h6>
								<Select
									showSearch
									onChange={(e) => {
										let obj2 = { ...obj };
										obj2.cutting_style = e;
										setobj(obj2);
									}}
									className="select"
									defaultValue="N/A"
									value={obj.cutting_style}
									dropdownRender={(menu) => (
										<div>
											{menu}
											<Divider style={{ margin: "4px 0" }} />
											<div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
												<Input
													style={{ flex: "auto" }}
													value={cuttingStyleName ? cuttingStyleName : ""}
													onChange={onCuttingStyleChange}
												/>
												{cuttingStyleName ? (
													<a
														style={{
															flex: "none",
															padding: "8px",
															display: "block",
															cursor: "pointer"
														}}
														onClick={addCuttingStyleOpt}
													>
														<PlusOutlined />
													</a>
												) : null}
											</div>
										</div>
									)}
								>
									{cuttingStyleArr.length && cuttingStyleArr.map((a, i) => (
										<Option value={a.value}>{a.value}</Option>
									))}
								</Select>
							</label>
						</div>
					</Col>
					<Col xs={7} sm={7} md={7} lg={7} xl={7}>
						<div className="inputDiv">
							<label>
								<h6>Carat Weights</h6>
								<div className="spaceBetween cutDiv">
									<Input
										onChange={(e) => {
											let obj2 = { ...obj };
											obj2.carat_weight = e.target.value;
											setobj(obj2);
										}}
										value={obj.carat_weight}
									/>
									<Select
										onChange={(e) => {
											let obj2 = { ...obj };
											obj2.weight_unit = e;
											setobj(obj2);
										}}
										className="select"
										defaultValue="Carats"
										value={obj.weight_unit}
									>
										<Option value="CT">Carats</Option>
										<Option value="Gm">Grams</Option>
									</Select>
								</div>
							</label>
						</div>
					</Col>
					<Col xs={7} sm={7} md={7} lg={7} xl={7}>
						<div className="inputDiv">
							<label>
								<h6>Object</h6>
								<Select
									showSearch
									onChange={(e) => {
										let obj2 = { ...obj };
										obj2.object = e;
										setobj(obj2);
									}}
									className="select"
									defaultValue=""
									value={obj.object}
									dropdownRender={(menu) => (
										<div>
											{menu}
											<Divider style={{ margin: "4px 0" }} />
											<div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
												<Input
													style={{ flex: "auto" }}
													value={objectName ? objectName : ""}
													onChange={onObjectsChange}
												/>
												{objectName ? (
													<a
														style={{
															flex: "none",
															padding: "8px",
															display: "block",
															cursor: "pointer"
														}}
														onClick={addObjectOpt}
													>
														<PlusOutlined />
													</a>
												) : null}
											</div>
										</div>
									)}
								>
									{objectArr.map((a, i) => {
										return <Option value={a.value}>{a.value}</Option>;
									})}
								</Select>
							</label>
						</div>
					</Col>
					<Col xs={7} sm={7} md={7} lg={7} xl={7}>
						<div className="inputDiv">
							<label>
								<h6>Color</h6>
								<Select
									showSearch
									onChange={(e) => {
										let obj2 = { ...obj };
										obj2.color = e;
										setobj(obj2);
									}}
									className="select"
									defaultValue=""
									value={obj.color}
									dropdownRender={(menu) => (
										<div>
											{menu}
											<Divider style={{ margin: "4px 0" }} />
											<div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
												<Input
													style={{ flex: "auto" }}
													value={colorName ? colorName : ""}
													onChange={onColorChange}
												/>
												{colorName ? (
													<a
														style={{
															flex: "none",
															padding: "8px",
															display: "block",
															cursor: "pointer"
														}}
														onClick={addColorOpt}
													>
														<PlusOutlined />
													</a>
												) : null}
											</div>
										</div>
									)}
								>
									{colorArr.map((a, i) => {
										return <Option value={a.name}>{a.name}</Option>;
									})}
								</Select>
							</label>
						</div>
					</Col>
					<Col xs={7} sm={7} md={7} lg={7} xl={7}>
						<div className="inputDiv">
							<label>
								<h6>Transparency</h6>
								<Select
									onChange={(e) => {
										let obj2 = { ...obj };
										obj2.transparency = e;
										setobj(obj2);
										setTransparency1("")
										setTransparency2("")
									}}
									className="select"
									defaultValue="Transparent" ref={selectRef}
									showAction={["click", "focus"]}

									value={transparency1 && transparency2 ? transparency1 + " to " + transparency2 : obj.transparency}
									dropdownRender={(menu) => (
										<div>
											{menu}
											<Divider style={{ margin: "4px 0" }} />
											<div style={{ display: "flex", flexWrap: "nowrap", justifyContent: "space-between", alignItems: "center", padding: 8 }}>
												<Select
													onChange={(e) => {
														// let obj2 = { ...obj };
														// obj2.transparency = e;
														// setobj(obj2);
														setTransparency1(e)
														selectRef.current.focus()

													}}
													className="select"
													style={{ width: "46%" }}
													defaultValue="Transparent"
													value={transparency1}
												>
													<Option value="Transparent">Transparent</Option>
													<Option value="Semi-Transparent">Semi-Transparent</Option>
													<Option value="Translucent">Translucent</Option>
													<Option value="Semi-Translucent">Semi-Translucent</Option>
													<Option value="Opaque">Opaque</Option>
												</Select>
												to
												<Select
													onChange={(e) => {
														// let obj2 = { ...obj };
														// obj2.transparency = e;
														// setobj(obj2);
														selectRef.current.focus()

														setTransparency2(e)
													}}
													className="select"
													defaultValue="Transparent"
													style={{ width: "46%" }}
													value={transparency2}
												>
													<Option value="Transparent">Transparent</Option>
													<Option value="Semi-Transparent">Semi-Transparent</Option>
													<Option value="Translucent">Translucent</Option>
													<Option value="Semi-Translucent">Semi-Translucent</Option>
													<Option value="Opaque">Opaque</Option>
												</Select>
											</div>
										</div>
									)}
								>
									<Option value="Transparent">Transparent</Option>
									<Option value="Semi-Transparent">Semi-Transparent</Option>
									<Option value="Translucent">Translucent</Option>
									<Option value="Semi-Translucent">Semi-Translucent</Option>
									<Option value="Opaque">Opaque</Option>
									<Option value="">N/A</Option>
									{transparency1 && transparency2 ? <Option value={transparency1 + " to " + transparency2}>{transparency1 + " to " + transparency2}</Option> : ""}
								</Select>
							</label>
						</div>
					</Col>
					<Col xs={7} sm={7} md={7} lg={7} xl={7}>
						<div className="inputDiv">
							<label>
								<h6>Species</h6>
								<Select
									showSearch
									onChange={(e) => {
										let obj2 = { ...obj };
										obj2.species = e;
										setobj(obj2);
									}}
									className="select"
									defaultValue="N/A"
									value={obj.species}
									dropdownRender={(menu) => (
										<div>
											{menu}
											<Divider style={{ margin: "4px 0" }} />
											<div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
												<Input
													style={{ flex: "auto" }}
													value={speciesName ? speciesName : ""}
													onChange={onSpeciesChange}
												/>
												{speciesName ? (
													<a
														style={{
															flex: "none",
															padding: "8px",
															display: "block",
															cursor: "pointer"
														}}
														onClick={addSpeciesOpt}
													>
														<PlusOutlined />
													</a>
												) : null}
											</div>
										</div>
									)}
								>

									{speciesArr.length && speciesArr.map((a, i) => (
										<Option value={a.value}>{a.value}</Option>
									))}
									<Option value="other">other</Option>
								</Select>
							</label>
						</div>
					</Col>
					<Col xs={7} sm={7} md={7} lg={7} xl={7}>
						<div className="inputDiv">
							<label>
								<h6>Variety</h6>
								<Select
									showSearch
									onChange={(e) => {
										let obj2 = { ...obj };
										obj2.variety = e;
										setobj(obj2);
									}}
									className="select"
									defaultValue="N/A"
									value={obj.variety}
									dropdownRender={(menu) => (
										<div>
											{menu}
											<Divider style={{ margin: "4px 0" }} />
											<div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
												<Input
													style={{ flex: "auto" }}
													value={varietyName ? varietyName : ""}
													onChange={onVarietysChange}
												/>
												{varietyName ? (
													<a
														style={{
															flex: "none",
															padding: "8px",
															display: "block",
															cursor: "pointer"
														}}
														onClick={addVarietyOpt}
													>
														<PlusOutlined />
													</a>
												) : null}
											</div>
										</div>
									)}
								>

									{varietyArr.length && varietyArr.map((a, i) => (
										<Option value={a.value}>{a.value}</Option>
									))}
								</Select>
							</label>
						</div>
					</Col>
					<Col xs={7} sm={7} md={7} lg={7} xl={7}>
						<div className="inputDiv commnetInput">
							<label>
								<h6>Comment</h6>
								<Input
									onChange={(e) => {
										let obj2 = { ...obj };
										obj2.comment = e.target.value;
										setobj(obj2);
									}}
									value={obj.comment}
								/>
							</label>
						</div>
					</Col>

					<Col xs={7} sm={7} md={7} lg={7} xl={7}>
						<div className="inputDiv">
							<label>
								<h6>Remarks</h6>
								<Input
									onChange={(e) => {
										let obj2 = { ...obj };
										obj2.remarks = e.target.value;
										setobj(obj2);
									}}
									value={obj.remarks}
								/>
							</label>
							<label>
								<h6>Origin</h6>
								<Select
									showSearch
									onChange={(e) => {
										let obj2 = { ...obj };
										obj2.origion_gem = e;
										setobj(obj2);
									}}
									className="select"
									defaultValue="N/A"
									value={obj.origion_gem}
									dropdownRender={(menu) => (
										<div>
											{menu}
											<Divider style={{ margin: "4px 0" }} />
											<div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
												<Input
													style={{ flex: "auto" }}
													value={originName ? originName : ""}
													onChange={onOriginsChange}
												/>
												{originName ? (
													<a
														style={{
															flex: "none",
															padding: "8px",
															display: "block",
															cursor: "pointer"
														}}
														onClick={addOriginOpt}
													>
														<PlusOutlined />
													</a>
												) : null}
											</div>
										</div>
									)}
								>
									{originArr.length && originArr.map((a, i) => (
										<Option value={a.value}>{a.value}</Option>
									))}
								</Select>
							</label>
						</div>
					</Col>

					<Col xs={7} sm={7} md={7} lg={7} xl={7}>
						<div className="inputDiv">
							<label>
								<h6>Color Grade</h6>
								<Select
									className="select"
									defaultValue="GEM"
									onChange={(e) => {
										let obj2 = { ...obj };
										obj2.color_grade = e;
										setobj(obj2);
									}}
									value={obj.color_grade}
								>
									<Option value="GEM">GEM</Option>
									<Option value="AAA">AAA</Option>
									<Option value="AA">AA</Option>
									<Option value="A">A</Option>
									<Option value="B">B</Option>
									<Option value="C">C</Option>
								</Select>
							</label>
						</div>
					</Col>

					<Col xs={7} sm={7} md={7} lg={7} xl={7}>
						<div className="inputDiv">
							<label>
								<h6>Cut Grade</h6>
								<Select
									className="select"
									defaultValue="Excellenet"
									onChange={(e) => {
										let obj2 = { ...obj };
										obj2.cut_grade = e;
										setobj(obj2);
									}}
									value={obj.cut_grade}
								>
									<Option value="Excellenet">Excellenet</Option>
									<Option value="Good">Good</Option>
									<Option value="Very_Good">Very Good</Option>
									<Option value="Good">Good</Option>
									<Option value="Fair">Fair</Option>
									<Option value="Poor">Poor</Option>
								</Select>
							</label>
						</div>
					</Col>

					<Col xs={7} sm={7} md={7} lg={7} xl={7}>
						<div className="inputDiv">
							<label>
								<h6>Clarity Grade</h6>
								<Select
									className="select"
									defaultValue="Loupe Clean"
									onChange={(e) => {
										let obj2 = { ...obj };
										obj2.clarity_grade = e;
										setobj(obj2);
									}}
									value={obj.clarity_grade}
								>
									<Option value="Loupe Clean">Loupe Clean</Option>
									<Option value="Eye Clean">Eye Clean</Option>
									<Option value="Slightly Included">Slightly Included</Option>
									<Option value="Moderately Included">Moderately Included</Option>
									<Option value="Highly Incuded">Highly Incuded</Option>
									<Option value="Severely Included">Severely Included</Option>
								</Select>
							</label>
							<label>
								<h6>Enhancements</h6>
								<Input
									onChange={(e) => {
										let obj2 = { ...obj };
										obj2.Enhancements = e.target.value;
										setobj(obj2);
									}}
									value={obj.Enhancements}
								/>
							</label>
						</div>
					</Col>

					<Col xs={7} sm={7} md={7} lg={7} xl={7}>
						<div className="inputDiv">
							<label>
								<h6>RI</h6>
								<div className="inputFlex">
									<Input
										onChange={(e) => {
											let obj2 = { ...obj };
											obj2.RI = e.target.value;
											setobj(obj2);
										}}
										value={obj.RI}
									/>
									<Input
										onChange={(e) => {
											let obj2 = { ...obj };
											obj2.RI1 = e.target.value;
											setobj(obj2);
										}}
										value={obj.RI1}
									/>
								</div>
							</label>
							{/* <label>
                                <h6></h6>
                            </label> */}
						</div>
					</Col>

					<Col xs={7} sm={7} md={7} lg={7} xl={7}>
						<div className="inputDiv">
							<label>
								<h6>Polariscope</h6>
								<Input
									onChange={(e) => {
										let obj2 = { ...obj };
										obj2.Polariscope = e.target.value;
										setobj(obj2);
									}}
									value={obj.Polariscope}
								/>
							</label>
						</div>
					</Col>

					<Col xs={7} sm={7} md={7} lg={7} xl={7}>
						<div className="inputDiv">
							<label>
								<h6>UV Flourescence</h6>
								<div className="inputFlex">
									<Input
										onChange={(e) => {
											let obj2 = { ...obj };
											obj2.uv_flourescence = e.target.value;
											setobj(obj2);
										}}
										value={obj.uv_flourescence}
									/>
									<Input
										onChange={(e) => {
											let obj2 = { ...obj };
											obj2.uv_flourescence1 = e.target.value;
											setobj(obj2);
										}}
										value={obj.uv_flourescence1}
									/>
								</div>
							</label>
						</div>
					</Col>

					<Col xs={7} sm={7} md={7} lg={7} xl={7}>
						<div className="inputDiv">
							<label>
								<h6>Microscopic evidence</h6>
								<Input
									onChange={(e) => {
										let obj2 = { ...obj };
										obj2.micro_evidence = e.target.value;
										setobj(obj2);
									}}
									value={obj.micro_evidence}
								/>
							</label>
						</div>
					</Col>

					<Col xs={7} sm={7} md={7} lg={7} xl={7}>
						<div className="inputDiv" style={{ marginTop: 10 }}>
							<div style={{ width: "100%" }}>
								<h6>Product Image</h6>
								<div className="imageBox">
									<div
										className="box"
										style={{
											backgroundImage: `url('${image
												? image
												: obj.fpath
													? obj.fpath
													: obj.product_pic
														? obj.product_pic
														: upload
												}')`
										}}
									></div>
									<Input onChange={(e) => handleChange(e)} type="file" placeholder="upload" />
								</div>
							</div>
						</div>
					</Col>
					<Col xs={7} sm={7} md={7} lg={7} xl={7}>
						<div className="inputDiv">
							<div>
								<h6>Instrument</h6>
								<Checkbox.Group
									className="inputFlex"
									options={plainOptions2}
									defaultValue={["Apple"]}
								/>
							</div>
						</div>
					</Col>
					<Col xs={7} sm={7} md={7} lg={7} xl={7}></Col>
				</Row>
			</div>
			{isEdit ? (
				<Button onClick={() => update()} type="primary" disabled={props.managerPanelLoading}>
					{props.managerPanelLoading ? <div className="loading1"></div> : "Update"}
				</Button>
			) : (
				<Button onClick={() => save()} type="primary" disabled={props.managerPanelLoading}>
					{props.managerPanelLoading ? <div className="loading1"></div> : "Save"}
				</Button>
			)}

			<Button onClick={() => props.history.goBack()} type="primary" style={{ margin: "0px 10px" }}>
				Cancel
			</Button>
		</div>
	);
}

const mapStateToProps = (store) => ({
	permission: store.users.permission,
	singleItemEntry: store.dataEntry.singleItemEntry,
	singleReportEntry: store.dataEntry.singleReportEntry,
	fields: store.FormValues
});

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			getSingleReport,
			updateCertificate,
			setCertificate,
			getSingleItem,
			getSingleItemConsultation,
			getCuttingStyleFormDataAdd,
			getShapesFormDatAdda,
			getObjectsFormDataAdd,
			getSpeciesFormDataAdd,
			getColorFormDataAdd,
			getVarietysFormDatAdda
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(MasterColoredForm);
